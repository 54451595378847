import styled from '@emotion/styled';
import { memo } from 'react-tracked';
import { useSnapshot } from 'valtio';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart';
import { store } from '~/pages/heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { QuoteCard } from './component/QuoteCard';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useEffect } from 'react';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { scrollbar2Css } from '~/css/scrollbarCss';
export const Sungop_SiderbarStockFutures = memo(function Sungop_SiderbarStockFutures() {
    const charting = useSnapshot(store.charting);
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const popularData = usePopularPick({
        date: day0.format('YYYY-MM-DD'),
        sort: 'value',
        limit: 50,
    }).resultSymbol;
    const stockFutures = useSnapshot(staticStore).symbol.stockFuturesMapper;
    const stockFuturesSymbol = Object.keys(stockFutures ?? []);
    const resultSymbol = popularData?.filter(item1 => stockFuturesSymbol?.some(item2 => item2 === item1)) ?? [''];
    const futuresDisctionary = useSnapshot(staticStore.symbol.stockFuturesMapper);
    const futuresSymbols = resultSymbol?.map(s => futuresDisctionary[s] + '-1') ?? [''];
    //
    useEffect(() => {
        signalrStore2.addQuote(futuresSymbols);
        return () => {
            signalrStore2.removeQuote(futuresSymbols);
        };
    }, [JSON.stringify(futuresSymbols)]);
    const value = signalrHooks2.useQuotes(useSignalr2QuoteSort(futuresSymbols ?? ['']));
    return (<styleds.container>
      <styleds.trendChartContnet>
        <TitleSymbolQuote.Default symbol={charting.symbol}/>
        <styleds.trendChart>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={20} priceTicksMargin={-10} priceTicksSize={11}/>
        </styleds.trendChart>
      </styleds.trendChartContnet>
      <styleds.symbolList>
        {futuresSymbols.map(s => {
            return (<styleds.symbolItem key={s}>
              <QuoteCard symbol={s}/>
            </styleds.symbolItem>);
        })}
      </styleds.symbolList>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #181a21;
    gap: 4px;
    padding: 8px;
  `,
    trendChartContnet: styled.div `
    ${fill_vertical_all_center};
    width: 100%;
    height: 160px;
    border-radius: 7px;
    background-color: #2d303eaa;
    padding: 6px;
  `,
    trendChart: styled.div `
    width: 100%;
    height: 132px;
  `,
    symbolList: styled.div `
    ${scrollbar2Css};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    padding: 2px;
  `,
    symbolItem: styled.div `
    flex: 1 0 calc(50% - 4px);
    height: 102px;
    border-radius: 6px;
    background-color: #1f222b;
  `,
};
