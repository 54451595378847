import styled from '@emotion/styled';
import { memo } from 'react-tracked';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { stockDecimal } from '~/modules/new-version-counsultant/Quote/stockDecimal';
import { Kbar } from '~/pages/edwin/component/Kbar';
import { store } from '~/pages/heineken_template/_private/store';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
export const QuoteCard = memo(function QuoteCard(props) {
    //const quote = props.quote
    const charting = useSnapshot(store).charting;
    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const name = quote?.symbol ?? '讀取中';
    const close = quote?.close ?? 0;
    const quoteChanges = processQuoteToReadable(quote);
    const change = quoteChanges?.closeChange ?? 0;
    const changePercent = quoteChanges?.closeChangePercent ?? 0;
    const changeSymbol = changePercent > 0 ? '+' : '';
    const volume = quote?.volume ?? 0;
    /** 第一種上色方式 */
    const changeColor = changePercent > 0 ? '#ff0000' : changePercent < 0 ? '#00ff00' : '';
    const selectedColor = charting.symbol === name ? '#ffffff' : '#787878';
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    return (<styleds.container selectedColor={selectedColor}>
        <styleds.symbolname>
          <span>{dictionary[name]}</span>
          <span>{name}</span>
        </styleds.symbolname>
        <styleds.symbolQuote color={changeColor}>
          {close.toFixed(stockDecimal(close))}
        </styleds.symbolQuote>
        <styleds.symbolChange color={changeColor}>
          <span>
            {changeSymbol}
            {change.toFixed(stockDecimal(close))}
          </span>
          <span>
            {changeSymbol}
            {changePercent}%
          </span>
        </styleds.symbolChange>
        <styleds.symbolInfo>{volume} 口</styleds.symbolInfo>
        <styleds.kbarItem>
          <Kbar quote={quote}/>
        </styleds.kbarItem>
      </styleds.container>);
});
const styleds = {
    container: styled.div `
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 25% 25% 25% 25%;
    font-size: 14px;
    border: 1px solid ${props => props.selectedColor};
    border-radius: 6px;
    position: relative;
    cursor: pointer;
  `,
    symbolname: styled.div `
    ${fill_horizontal_all_center};
  `,
    symbolQuote: styled.div `
    ${fill_horizontal_all_center};
    color: ${props => props.color};
    font-size: 15px;
    font-weight: bold;
  `,
    symbolChange: styled.div `
    ${fill_horizontal_all_center};
    color: ${props => props.color};
    font-size: 13px;
    font-weight: 500;
    gap: 16px;
  `,
    symbolInfo: styled.div `
    ${fill_horizontal_all_center};
    font-size: 13px;
    color: #ffee00;
    background-color: #282b36;
  `,
    kbarItem: styled.div `
    position: absolute;
    width: 24px;
    height: 36%;
    top: 24%;
  `,
};
